import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import useWindowSize from "../hooks/useGetWindowSize";

const AuthenticatedLayout = (): JSX.Element => {
  const size = useWindowSize();
  return (
    <React.Fragment>
      <Header />
      <Box padding={size.width > 1024 ? "64px" : "32px"}>
        <Outlet />
      </Box>
      <Footer />
    </React.Fragment>
  );
};

export default AuthenticatedLayout;
