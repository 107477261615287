import React from "react";
import { Routes, Route } from "react-router";
import AuthenticatedLayout from "../layout/AuthenticatedLayout";
import Home from "../pages/Home";
import PageNotFound from "../pages/404";
import UnAuthenticatedLayout from "../layout/UnAuthenticatedLayout";
import InscriptionsParticipationsDetails from "../pages/Inscriptions_Participations_Details";
import Breakdown0fCollaborators from "../pages/Breakdown_0f_Collaborators";
import InscriptionsAndParticipationsDetails from "../pages/InscriptionsAndParticipationsDetails";
import ParticipationsAndRegionsDetails from "../pages/ParticipationsAndRegionsDetails";
import ParticipationsAndRegionsDetails2 from "../pages/ParticipationsAndRegionsDetails2";
import DetailsOfHostingPossibilities from "../pages/DetailsOfHostingPossibilities";
import { type PublicClientApplication } from "@azure/msal-browser";

const AuthRoutes = ({
  msalInstance,
}: {
  msalInstance: PublicClientApplication;
}): JSX.Element => {
  return (
    <Routes>
      <Route element={<AuthenticatedLayout />}>
        <Route path="/" element={<Home />} />
        <Route
          path="/inscriptions_details"
          element={<InscriptionsParticipationsDetails />}
        />
        <Route
          path="/inscriptionsandparticipationsdetails"
          element={<InscriptionsAndParticipationsDetails />}
        />
        <Route
          path="/participationsandregionsdetails"
          element={<ParticipationsAndRegionsDetails />}
        />
        <Route
          path="/participationsandregionsdetails2"
          element={<ParticipationsAndRegionsDetails2 />}
        />

        <Route
          path="/breakdown_of_collaborators"
          element={<Breakdown0fCollaborators />}
        />
        <Route
          path="/detailsofhostingpossibilities"
          element={<DetailsOfHostingPossibilities />}
        />
      </Route>
      <Route element={<UnAuthenticatedLayout />}>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default AuthRoutes;
