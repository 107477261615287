import React, { useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import { ReactComponent as DownArrow } from "../assets/icons/down-arrow.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";

import "react-datepicker/dist/react-datepicker.css";

interface IVmVCalenderProps {
  date: Date | null;
  handleChangeDate: (date: any) => void;
}

registerLocale("fr", fr);

const VmVCalender = ({
  date,
  handleChangeDate,
}: IVmVCalenderProps): JSX.Element => {
  setDefaultLocale("fr");

  return (
    <div className="calender_container">
      <DatePicker
        // showIcon
        // selectsRange
        // isClearable
        locale="fr"
        minDate={new Date("4/1/2023")}
        maxDate={new Date("6/1/2023")}
        fixedHeight
        dateFormat="dd/MM/yyyy"
        placeholderText="Toutes les dates"
        selected={date}
        onChange={(selectedDate) => {
          handleChangeDate(selectedDate);
        }}
      />
      {date !== null ? (
        <Close
          onClick={() => {
            handleChangeDate(null);
          }}
          width={30}
          height={30}
        />
      ) : (
        <DownArrow width={20} height={20} />
      )}
    </div>
  );
};

export default VmVCalender;
