/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/prop-types */

import { Box, Typography } from "@mui/material";
import VmVButton from "../components/VmVButtons";
import { ReactComponent as LeftArrow } from "../assets/icons/left-arrow.svg";
import { colors, typography } from "../constants/theme";
import { CSVLink } from "react-csv";
import { ReactComponent as CSV } from "../assets/icons/csv.svg";
import VmVDataArray from "../components/VmVDataArray";
import { BarChart, XAxis, YAxis, Bar, LabelList } from "recharts";
import VmVTextInput from "../components/VmVTextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchData } from "../helpers/fetch";
import { useQuery } from "@tanstack/react-query";
import { convertDataToCSV, downloadCSV } from "../helpers/DataToCsv";
import { useState } from "react";

const arrayCells = [
  "Nom de l’établissement",
  "Localisation",
  "Restauration",
  "Maintenance",
  "Animation",
  "Accueil et administration",
  "Hôtellerie (ASH)",
  "Hôtellerie (Serveur)",
];

const DetailsOfHostingPossibilities = (): JSX.Element => {
  const navigate = useNavigate();
  const [text, setText] = useState("");

  const { data } = useQuery({
    queryKey: ["etablissements"],
    queryFn: async () =>
      await fetchData("etablissements?page=1&pageLimit=10000"),
  });

  const formateData = (dataToFormat: any): any[] => {
    const reformateData: any[] = [];
    if (dataToFormat !== undefined) {
      dataToFormat?.etablissements?.forEach((element) => {
        reformateData.push({
          "Nom de l’établissement": element?.name,

          Localisation: element?.localisation?.countrySecondarySubdivision,
          Restauration: element?.nombre_disponibilites_restauration,
          Maintenance: element?.nombre_disponibilites_maintenance,
          Animation: element?.nombre_disponibilites_animation,
          "Accueil et administration":
            element?.nombre_disponibilites_accueil_et_administration,
          "Hôtellerie (Serveur)": Number(
            element?.nombre_disponibilites_hotellerie_serveur,
          ),
          "Hôtellerie (ASH)": Number(
            element?.nombre_disponibilites_hotellerie_ASH,
          ),
        });
      });
    }

    return reformateData;
  };

  const onClickDownloadCSV = (): void => {
    const csvData = convertDataToCSV(formateData(data));
    downloadCSV(csvData);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <VmVButton
        onClick={() => {
          navigate(-1);
        }}
        type="back"
        LeftIcon={<LeftArrow stroke={colors.darkGrey[200]} />}
      >
        Retour
      </VmVButton>
      <Box
        alignSelf="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography color={colors.darkBlue} sx={{ ...typography.title1 }}>
          Détails des possibilités d’accueils
        </Typography>
        <Box
          alignSelf="center"
          display="flex"
          flexDirection="column"
          gap={2.5}
          mb={8}
        >
          <Box mt={8} alignSelf="center">
            <DetailsOfHostingPossibilitiesChart />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={8}>
          <Box display="flex" justifyContent="center">
            <VmVTextInput
              value={text}
              onChange={(event) => {
                setText(event.target.value);
              }}
              placeholder="Rechercher un établissement"
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <VmVButton onClick={() => {}} type="secondary" RightIcon={<CSV />}>
              <CSVLink
                data={formateData(data)}
                filename={"data.csv"}
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                  fontFamily: "inherit",
                }}
                target="_blank"
              >
                Exporter CSV
              </CSVLink>
            </VmVButton>
          </Box>

          <VmVDataArray
            showFooter
            cells={arrayCells}
            rows={formateData(data).filter((value) => {
              return value["Nom de l’établissement"]
                ?.toLowerCase()
                ?.includes(text.toLowerCase());
            })}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DetailsOfHostingPossibilities;

export const DetailsOfHostingPossibilitiesChart = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}): JSX.Element => {
  const { data } = useQuery({
    queryKey: ["count-disponibilites-accueil"],
    queryFn: async () =>
      await fetchData("etablissements/count-disponibilites-accueil"),
  });

  const { pathname } = useLocation();

  const formateData = (
    dataToFormat: any,
  ): Array<
    | {
        name: string;
        value: number;
        label: string;
      }
    | {
        name: string;
        value: number;
        label?: undefined;
      }
  > => {
    if (dataToFormat !== undefined) {
      return [
        {
          name: "Disponibilités d’accueil en restauration",
          value: data?.totalRestauration,
          label: data?.totalRestauration,
        },
        {
          name: "Disponibilités d’accueil en maintenance",
          value: data?.totalMaintenance,
        },
        {
          name: "Disponibilités d’accueil en animation",
          value: data?.totalAnimation,
        },

        {
          name: "Disponibilités d’accueil en accueil et administration",
          value: data?.totalAccueilEtAdministration,
        },
        {
          name: "Disponibilités d’accueil en hôtellerie (ASH)",
          value: parseInt(data?.totalHotellerieASH),
        },
        {
          name: "Disponibilités d’accueil en hôtellerie  (Serveur)",
          value: parseInt(data?.totalHotellerieServeur),
        },
      ];
    }
    return [];
  };

  return (
    <Box
      alignSelf="center"
      display="flex"
      flexDirection="column"
      // gap={2.5}
      mt={3}
      mb={3}
    >
      <BarChart
        data={formateData(data)}
        layout="vertical"
        barCategoryGap={10}
        width={width ?? 980}
        height={width ?? 300}
      >
        <XAxis
          type="number"
          // hide={pathname === "/"}
        />
        <YAxis
          type="category"
          width={400}
          dataKey="name"
          // tick={{ fill: colors.orange, ...typography.body1 }}
          tick={<CustomizedTick />}
        />
        <LabelList dataKey="name" />

        <Bar dataKey="value" fill={colors.orange} />
      </BarChart>
    </Box>
  );
};

function CustomizedTick(props): JSX.Element {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x - 3},${y - 18})`}>
      <text
        x={0}
        y={-2}
        dy={16}
        textAnchor="end"
        fill={colors.orange}
        style={{
          ...typography.body1,
        }}
      >
        Disponibilités d’accueil
      </text>
      <text
        x={0}
        y={12}
        dy={16}
        textAnchor="end"
        fill={colors.orange}
        style={{
          ...typography.body1,
        }}
        // transform="rotate(-35)"
      >
        {payload.value.split("Disponibilités d’accueil")}
      </text>
    </g>
  );
}
