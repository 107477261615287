import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { Chart, ArcElement } from "chart.js";
import ScrollToTop from "./helpers/ScrollToTop";
import { RecoilRoot } from "recoil";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config/authConfig";
import { MsalProvider } from "@azure/msal-react";
Chart.register(ArcElement);

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <RecoilRoot>
        <BrowserRouter>
          <ScrollToTop />
          <App msalInstance={msalInstance} />
        </BrowserRouter>
      </RecoilRoot>
    </MsalProvider>
  </React.StrictMode>,
);
