export const InscriptionsAndParticipationsDetailsCells = [
  "Nom de l’établissement",
  "Métiers",
  "Nombre de participations (collaborateur)",
  "Nombres d’inscriptions (établissement)",
];

export const InscriptionsAndParticipationsDetailsRows = [
  {
    "Nom de l’établissement": "Korian Villa Saint Antoine",
    Métiers: "Restauration",
    "Nombre de participations": 10,
    "Nombres d’inscriptions": 20,
  },
  {
    "Nom de l’établissement": "Korian Villa Saint Antoine",
    Métiers: "Restauration",
    "Nombre de participations": 10,
    "Nombres d’inscriptions": 20,
  },
  {
    "Nom de l’établissement": "Korian Villa Saint Antoine",
    Métiers: "Restauration",
    "Nombre de participations": 10,
    "Nombres d’inscriptions": 20,
  },
  {
    "Nom de l’établissement": "Korian Villa Saint Antoine",
    Métiers: "Restauration",
    "Nombre de participations": 10,
    "Nombres d’inscriptions": 20,
  },
  {
    "Nom de l’établissement": "Korian Villa Saint Antoine",
    Métiers: "Restauration",
    "Nombre de participations": 10,
    "Nombres d’inscriptions": 20,
  },
  {
    "Nom de l’établissement": "Korian Villa Saint Antoine",
    Métiers: "Restauration",
    "Nombre de participations": 10,
    "Nombres d’inscriptions": 20,
  },
  {
    "Nom de l’établissement": "Korian Villa Saint Antoine",
    Métiers: "Restauration",
    "Nombre de participations": 10,
    "Nombres d’inscriptions": 20,
  },
  {
    "Nom de l’établissement": "Korian Villa Saint Antoine",
    Métiers: "Restauration",
    "Nombre de participations": 10,
    "Nombres d’inscriptions": 20,
  },
];

export const InscriptionsAndParticipationsDetailsData = [
  { name: "Restauration", x: 12, y: 230 },
  { name: "Maintenance", x: 22, y: 3 },
  { name: "Animation", x: 13, y: 15 },
  { name: "Accueil et administration", x: 44, y: 35 },
  { name: "Hôtellerie", x: 44, y: 35 },
];
