import { Box, Typography } from "@mui/material";
import { colors, typography } from "../constants/theme";

interface VmVLabelProps {
  label: string;
  color: string;
}

const VmVLabel = ({ label, color }: VmVLabelProps): JSX.Element => {
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      width="100%"
      gap={1}
    >
      <Box width={15} height={15} bgcolor={color} borderRadius={1}></Box>
      <Typography
        color={colors.darkBlue}
        sx={{ ...typography.body1, fontWeight: 600 }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default VmVLabel;
