import { Box, Typography } from "@mui/material";
import { Pie } from "react-chartjs-2";
import { colors, typography } from "../constants/theme";
import { useEffect } from "react";

interface IVmVPieProps {
  participantsNumber: number;
  total?: number;
  area: string;
  color?: string;
}

const VmVPie = ({
  participantsNumber,
  area,
  color,
  total,
}: IVmVPieProps): JSX.Element => {
  const calculatePourcentage = (
    participations: number,
    total: number,
  ): number => {
    return (participations * 100) / total;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={1}
      p={8}
      // mb={7}
    >
      <Typography
        color={colors.darkBlue}
        sx={{ ...typography.body1, fontWeight: 600 }}
        textAlign="center"
      >
        {participantsNumber} participations
      </Typography>
      <Pie
        data={{
          datasets: [
            {
              data: [
                calculatePourcentage(participantsNumber, total ?? 100),
                total ??
                  100 - calculatePourcentage(participantsNumber, total ?? 100),
              ],
              backgroundColor: [color ?? colors.yellow, "transparent"],
              borderColor: [color ?? colors.yellow],
              borderWidth: 0,
            },
          ],
        }}
      />
      <Typography
        textAlign="center"
        color={colors.darkBlue}
        sx={{ ...typography.body1 }}
      >
        {area}
      </Typography>
    </Box>
  );
};

export default VmVPie;
