import { Grid } from "@mui/material";
import VmVCardKeys from "../VmVCardKeys";
import { useQuery } from "@tanstack/react-query";
import { fetchData } from "../../helpers/fetch";

interface HomeCardsData {
  daysRemainingParticipation: number;
  daysRemainingInscription: number;
  collaborateursCount: number;
  etablissementsCount: number;
}

const HomeCards = (): JSX.Element => {
  const { data } = useQuery({
    queryKey: ["misc/first-page-stat"],
    queryFn: async () => await fetchData("misc/first-page-stat"),
  });

  return (
    <Grid container justifyContent="center" my={15}>
      <Grid item lg={2} sm={6} xs={12}>
        <VmVCardKeys
          title={
            (data as HomeCardsData)?.daysRemainingInscription > 0
              ? `J -${(data as HomeCardsData)?.daysRemainingInscription}`
              : "Clôturé"
          }
          subtitle="Délais des participations"
          description={
            (data as HomeCardsData)?.daysRemainingInscription > 0
              ? "avant clôture"
              : ""
          }
        />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <VmVCardKeys
          title={`${
            (data as HomeCardsData)?.etablissementsCount ?? 0
          } participations`}
          subtitle="Total des participations établissements"
        />
      </Grid>
      <Grid item lg={2} sm={6} xs={12}>
        <VmVCardKeys
          title={
            (data as HomeCardsData)?.daysRemainingParticipation > 0
              ? `J -${(data as HomeCardsData)?.daysRemainingParticipation}`
              : "Clôturé"
          }
          subtitle="Délais des inscriptions"
          description={
            (data as HomeCardsData)?.daysRemainingParticipation > 0
              ? "avant clôture"
              : ""
          }
          color={"green"}
        />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <VmVCardKeys
          title={`${
            (data as HomeCardsData)?.collaborateursCount ?? 0
          } inscriptions`}
          subtitle="Total des collaborateurs(trices)"
          color="green"
        />
      </Grid>
    </Grid>
  );
};

export default HomeCards;
