/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Typography } from "@mui/material";
import React from "react";
import { colors, typography } from "../constants/theme";

interface IVmVButtonProps {
  children: React.ReactNode;
  type: "primary" | "secondary" | "back";
  onClick: () => void;
  LeftIcon?: React.ReactNode;
  RightIcon?: React.ReactNode;
}

const ButtonStyles = {
  primary: {
    height: "31px",
    backgroundColor: colors.blue,
    color: colors.white,
    borderRadius: "6px",
    padding: "11px 16px",
    cursor: "pointer",
    boxShadow: colors.dropShadow[300],
  },
  secondary: {
    maxHeight: "42px",
    backgroundColor: colors.white,
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: "6px",
    padding: "11px 16px",
    cursor: "pointer",
    color: colors.darkBlue,
    fontFamily: "Montserrat",
    boxShadow: colors.dropShadow[300],
  },
  back: {
    maxHeight: "42px",
    background: colors.lightGrey[200],
    border: `1px solid ${colors.darkGrey[200]}`,
    padding: "11px 16px",
    borderRadius: "20px 6px 6px 20px",
    color: colors.darkGrey[200],
    cursor: "pointer",
    // width: "fit-content",
  },
};

const VmVButton = ({
  children,
  onClick,
  LeftIcon,
  RightIcon,
  type,
}: IVmVButtonProps): JSX.Element => {
  const { body1 } = typography;

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      width="fit-content"
      sx={{ ...ButtonStyles[type] }}
      // component="button"
      onClick={onClick}
    >
      {LeftIcon ? <Box mt="5px">{LeftIcon}</Box> : null}
      <Typography
        sx={{
          ...body1,
          fontWeight: type === "back" ? 600 : 400,
          fontFamily: type === "back" ? "Roboto, sans-serif" : "inherit",
          color: "inherit",
        }}
      >
        {children}
      </Typography>
      {RightIcon ? <Box mt="5px">{RightIcon}</Box> : null}
    </Box>
  );
};

export default VmVButton;
