interface IOption {
  value: string;
  label: string;
}

const formatDropDownData = (dataToFormat: any): IOption[] => {
  if (dataToFormat === undefined) return [];
  return dataToFormat?.map((element: any) => {
    return {
      value: element?.label ?? element?.name,
      label: element?.label ?? element?.name,
    };
  });
};

export default formatDropDownData;
