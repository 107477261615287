/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import AuthRoutes from "./routes/Auth.routes";
import {
  EventType,
  InteractionType,
  type PublicClientApplication,
} from "@azure/msal-browser";
import UnAuthRoutes from "./routes/UnAuth.routes";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { b2cPolicies } from "./config/authConfig";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import userAtom, { type IUser } from "./context/userAtom";
import SignIn from "./pages/SignIn";

// Create a client
const queryClient = new QueryClient();

function App({
  msalInstance,
}: {
  msalInstance: PublicClientApplication;
}): JSX.Element {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <MsalCallback />
        <AuthenticatedTemplate>
          <AuthRoutes msalInstance={msalInstance} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          {/* <UnAuthRoutes /> */}
          <SignIn />
        </UnauthenticatedTemplate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

const MsalCallback = (): JSX.Element => {
  const { instance } = useMsal();
  const [_, setUser] = useRecoilState<IUser | null>(userAtom);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const callbackId = instance.addEventCallback(async (event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        alert(
          "Veuillez contacter votre administrateur pour réinitialiser votre mot de passe.",
        );
        if (
          event.error &&
          event.error.errorMessage.indexOf("AADB2C90118") > -1
        ) {
          if (event.interactionType === InteractionType.Redirect) {
            await instance.loginRedirect(
              b2cPolicies.authorities.forgotPassword,
            );
          } else if (event.interactionType === InteractionType.Popup) {
            instance
              .loginPopup(b2cPolicies.authorities.forgotPassword)
              .catch(() => {});
          }
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        if (event?.payload) {
          if (event.payload.idToken && event.payload.idTokenClaims.oid) {
            setUser({
              email: event.payload.account.idTokenClaims.email,
              fullname: event.payload.account.idTokenClaims.name,
              b2cToke: event.payload.idToken,
            });
          } else {
            await instance.logout();
            return;
          }
          if (
            event.payload.idTokenClaims.acr === b2cPolicies.names.forgotPassword
          ) {
            window.alert(
              "Password has been reset successfully. \nPlease sign-in with your new password",
            );
            await instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return <></>;
};

export default App;
