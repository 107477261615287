import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { colors, typography } from "../constants/theme";
import VmVButton from "../components/VmVButtons";

import VMVPaper from "../components/VMVPaper";

import { useNavigate } from "react-router-dom";

import { DetailsOfHostingPossibilitiesChart } from "./DetailsOfHostingPossibilities";
import { InscriptionsParticipationsDetailsChart } from "./Inscriptions_Participations_Details";
import { InscriptionsAndParticipationsDetailsChart } from "./InscriptionsAndParticipationsDetails";
import HomeCards from "../components/Home/HomeCards";
import { Breakdown0fCollaboratorsChart } from "./Breakdown_0f_Collaborators";
import { ParticipationsAndRegionsDetails2Chart } from "./ParticipationsAndRegionsDetails2";
import { ParticipationsAndRegionsDetailsChart } from "./ParticipationsAndRegionsDetails";

const Home = (): JSX.Element => {
  const navigate = useNavigate();

  const handleClickDetailsButton = (path: string): void => {
    navigate("/" + path);
  };

  return (
    <Box>
      <Typography
        color={colors.darkBlue}
        textAlign="center"
        style={{ ...typography.mainTitle }}
        mb={8}
      >
        Vis ma vie
      </Typography>
      {/* <HomeActions /> */}
      <HomeCards />
      <Grid container gap={8}>
        <Grid container item xs={12} gap={8}>
          <Grid container item lg={7} xs={12} gap={8}>
            <Grid item xs={12}>
              <VMVPaper
                title="Détails des inscriptions et des participations"
                height={792}
              >
                <InscriptionsParticipationsDetailsChart
                  width={600}
                  height={500}
                  home
                />
                <VmVButton
                  type="primary"
                  onClick={() => {
                    handleClickDetailsButton("inscriptions_details");
                  }}
                >
                  Voir le détails
                </VmVButton>
              </VMVPaper>
            </Grid>
            <Grid item xs={12}>
              <VMVPaper
                title="Détails des possibilités d’accueils"
                height={885}
              >
                <Box display="flex" flexDirection="column" gap={2.5}>
                  <DetailsOfHostingPossibilitiesChart
                    width={750}
                    height={750}
                  />
                </Box>
                <VmVButton
                  type="primary"
                  onClick={() => {
                    handleClickDetailsButton("detailsofhostingpossibilities");
                  }}
                >
                  Voir le détails
                </VmVButton>
              </VMVPaper>
            </Grid>
          </Grid>
          <Grid item lg={4} xs={12}>
            <VMVPaper title="Répartitions des collaborateurs(trices)">
              <Breakdown0fCollaboratorsChart direction="column" />
              <VmVButton
                type="primary"
                onClick={() => {
                  handleClickDetailsButton("breakdown_of_collaborators");
                }}
              >
                Voir le détails
              </VmVButton>
            </VMVPaper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <VMVPaper
            title={
              <>
                Taux de remplissage des créneaux disponibles <br />
                <span style={{ fontWeight: 400, fontSize: 18 }}>
                  (Nombre de participation collaborateurs VERSUS nombre de
                  créneaux disponibles)
                </span>
              </>
            }
          >
            <InscriptionsAndParticipationsDetailsChart home />
            <Box display="flex" justifyContent="center">
              <VmVButton
                type="primary"
                onClick={() => {
                  handleClickDetailsButton(
                    "inscriptionsandparticipationsdetails",
                  );
                }}
              >
                Voir le détails
              </VmVButton>
            </Box>
          </VMVPaper>
        </Grid>
        <Grid item xs={12}>
          <VMVPaper title="Participation des établissements par région">
            <Grid container gap={8}>
              <Grid item xs={12}>
                <Box
                  sx={{ backgroundColor: colors.white }}
                  // width="max-content"
                  padding="32px"
                  borderRadius="8px"
                  mt={3}
                  mb={4}
                  mx={6}
                  alignItems="center"
                >
                  <Typography
                    color={colors.darkBlue}
                    textAlign="center"
                    mb={8}
                    sx={{ ...typography.body1, fontWeight: 600 }}
                  >
                    EHPAD
                  </Typography>
                  <ParticipationsAndRegionsDetailsChart home />
                  <Box display="flex" justifyContent="center" mt={8}>
                    <VmVButton
                      type="primary"
                      onClick={() => {
                        handleClickDetailsButton(
                          "participationsandregionsdetails",
                        );
                      }}
                    >
                      Voir le détails
                    </VmVButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{ backgroundColor: colors.white }}
                  padding="32px"
                  borderRadius="8px"
                  mt={3}
                  mb={4}
                  mx={6}
                  alignItems="center"
                >
                  <Typography
                    color={colors.darkBlue}
                    textAlign="center"
                    mb={8}
                    sx={{ ...typography.body1, fontWeight: 600 }}
                  >
                    Clinique (SSR & Santé Mentale)
                  </Typography>
                  <ParticipationsAndRegionsDetails2Chart home />
                  <Box display="flex" justifyContent="center" mt={8}>
                    <VmVButton
                      type="primary"
                      onClick={() => {
                        handleClickDetailsButton(
                          "participationsandregionsdetails2",
                        );
                      }}
                    >
                      Voir le détails
                    </VmVButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </VMVPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
