export const InscriptionsParticipationsDetailsRows = [
  {
    Date: "24/04/2023",
    "Nombres d’inscriptions collaborateur(trice)s": "10",
    "Nombre de créneaux proposés par les établissements": "5",
  },
  {
    Date: "24/04/2023",
    "Nombres d’inscriptions collaborateur(trice)s": "10",
    "Nombre de créneaux proposés par les établissements": "5",
  },
  {
    Date: "24/04/2023",
    "Nombres d’inscriptions collaborateur(trice)s": "10",
    "Nombre de créneaux proposés par les établissements": "5",
  },
  {
    Date: "24/04/2023",
    "Nombres d’inscriptions collaborateur(trice)s": "10",
    "Nombre de créneaux proposés par les établissements": "5",
  },
];

export const InscriptionsParticipationsDetailsCells = [
  "Date",
  "Nombre de créneaux proposés par les établissements",
  "Nombres d’inscriptions collaborateur(trice)s",
];

export const InscriptionsParticipationsDetailsChartData = [
  {
    uv: 4000,
    pv: 0,
  },
  {
    uv: 3000,
    pv: 0,
  },
  {
    uv: 2000,
    pv: 0,
  },
  {
    uv: 0,
    pv: 2000,
  },
  {
    uv: 0,
    pv: 3000,
  },
  {
    uv: 0,
    pv: 4000,
  },
];
