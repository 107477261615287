/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Typography } from "@mui/material";
import VmVButton from "../components/VmVButtons";
import VmVDataArray from "../components/VmVDataArray";
import DropDownData from "../dropdownsData/metier.json";
import { CSVLink } from "react-csv";
import { ReactComponent as LeftArrow } from "../assets/icons/left-arrow.svg";
import { colors, typography } from "../constants/theme";
import { ReactComponent as CSV } from "../assets/icons/csv.svg";
import { BarChart, XAxis, YAxis, Bar, Cell, LabelList } from "recharts";
import VmVSelect from "../components/VmVSelect";
import { useNavigate } from "react-router-dom";
import VmVTextInput from "../components/VmVTextInput";
import {
  InscriptionsAndParticipationsDetailsCells,
  InscriptionsAndParticipationsDetailsData,
} from "../data/InscriptionsAndParticipationsDetailsData";
import { useQuery } from "@tanstack/react-query";
import { convertDataToCSV, downloadCSV } from "../helpers/DataToCsv";
import { fetchData, fetchDropDownData } from "../helpers/fetch";
import formatDropDownData from "../helpers/formatDropDown";
import { useEffect, useReducer } from "react";
import useWindowSize from "../hooks/useGetWindowSize";

interface IReducerState {
  text: string;
  metier: string;
}

interface IReducerAction {
  type: string;
  payload: string;
}

const initialState: IReducerState = {
  text: "",
  metier: "",
};

const reducer = (
  state: IReducerState,
  action: IReducerAction,
): IReducerState => {
  switch (action.type) {
    case "SET_TEXT":
      return {
        ...state,
        text: action.payload,
      };
    case "SET_METIER":
      return {
        ...state,
        metier: action.payload,
      };
    case "RESET": {
      return {
        text: "",
        metier: "",
      };
    }

    default:
      return state;
  }
};

const InscriptionsAndParticipationsDetails = (): JSX.Element => {
  const navigation = useNavigate();
  const [filters, dispatch] = useReducer(reducer, initialState);
  const { data } = useQuery({
    queryKey: ["etablissements/inscription-participations"],
    queryFn: async () =>
      await fetchData(
        "etablissements/inscription-participations?page=1&pageLimit=10000",
      ),
  });

  // const { data: metierData } = useQuery({
  //   queryKey: ["drop-downs/metiers"],
  //   queryFn: async () => await fetchDropDownData("drop-downs/metiers"),
  // });

  const formateData = (dataToFormat: any): any[] => {
    const reformateData: any[] = [];
    if (dataToFormat !== undefined) {
      dataToFormat?.etablissements?.forEach((element) => {
        reformateData.push(
          ...[
            {
              "Nom de l’établissement": element?.name,
              Métiers: "Restauration",
              "Nombres d’inscriptions (établissement)":
                element?.nombre_disponibilites_restauration ?? 0,
              "Nombre de participations (collaborateur)":
                element?.nombre_participations_restauration ?? 0,
            },
            {
              "Nom de l’établissement": element?.name,
              Métiers: "Maintenance",
              "Nombres d’inscriptions (établissement)":
                element?.nombre_disponibilites_maintenance ?? 0,
              "Nombre de participations (collaborateur)":
                element?.nombre_participations_maintenance ?? 0,
            },
            {
              "Nom de l’établissement": element?.name,
              Métiers: "Animation",
              "Nombres d’inscriptions (établissement)":
                element?.nombre_disponibilites_animation ?? 0,
              "Nombre de participations (collaborateur)":
                element?.nombre_participations_animation ?? 0,
            },
            {
              "Nom de l’établissement": element?.name,
              Métiers: "Accueil et administration",
              "Nombres d’inscriptions (établissement)":
                element?.nombre_disponibilites_accueil_et_administration ?? 0,
              "Nombre de participations (collaborateur)":
                element?.nombre_participations_accueil_et_administration ?? 0,
            },
            {
              "Nom de l’établissement": element?.name,
              Métiers: "Hôtellerie - Agent de service hôtelier (ASH)",
              "Nombres d’inscriptions (établissement)": parseInt(
                element?.nombre_disponibilites_hotellerie_ASH ?? 0,
              ),
              "Nombre de participations (collaborateur)": parseInt(
                element?.nombre_participations_hotellerie_ASH ?? 0,
              ),
            },
            {
              "Nom de l’établissement": element?.name,
              Métiers: "Hôtellerie - Serveur(euse)",
              "Nombres d’inscriptions (établissement)": parseInt(
                element?.nombre_disponibilites_hotellerie_serveur ?? 0,
              ),
              "Nombre de participations (collaborateur)": parseInt(
                element?.nombre_participations_hotellerie_serveur ?? 0,
              ),
            },
          ],
        );
      });
    }

    return reformateData;
  };

  const filterData = (dataToFilter: any[], filters: IReducerState): any[] => {
    if (dataToFilter === undefined || dataToFilter.length === 0) return [];
    return dataToFilter
      .filter((value) => {
        return value["Nom de l’établissement"]
          ?.toLowerCase()
          ?.includes(filters.text.toLowerCase());
      })
      .filter((value) => {
        return (
          value["Métiers"]?.toLowerCase() === filters.metier.toLowerCase() ||
          filters.metier === ""
        );
      });
  };

  const onClickDownloadCSV = (): void => {
    const csvData = convertDataToCSV(formateData(data));
    downloadCSV(csvData);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <VmVButton
        onClick={() => {
          navigation(-1);
        }}
        type="back"
        LeftIcon={<LeftArrow stroke={colors.darkGrey[200]} />}
      >
        Retour
      </VmVButton>
      <Box
        alignSelf="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography
          color={colors.darkBlue}
          sx={{ ...typography.title1 }}
          textAlign="center"
        >
          Taux de remplissage des créneaux disponibles <br />
          <span style={{ fontWeight: 400, fontSize: 18 }}>
            (Nombre de participation collaborateurs VERSUS nombre de créneaux
            disponibles)
          </span>
        </Typography>
        <InscriptionsAndParticipationsDetailsChart />
        <Box display="flex" flexDirection="column" gap={8}>
          <Box display="flex" justifyContent="center">
            <VmVTextInput
              placeholder="Rechercher un établissement"
              value={filters.text}
              onChange={(event) => {
                dispatch({ type: "SET_TEXT", payload: event.target.value });
              }}
            />
          </Box>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" gap={1}>
                <VmVButton
                  onClick={() => {
                    dispatch({ type: "RESET", payload: "" });
                  }}
                  type="primary"
                >
                  Réinitialiser
                </VmVButton>
                <VmVSelect
                  label="Tous les métiers"
                  options={formatDropDownData(DropDownData)}
                  value={filters.metier}
                  onChange={(value) => {
                    dispatch({ type: "SET_METIER", payload: value });
                  }}
                />
              </Box>
              <Box>
                <VmVButton
                  onClick={() => {}}
                  type="secondary"
                  RightIcon={<CSV />}
                >
                  <CSVLink
                    data={formateData(data)}
                    filename={"data.csv"}
                    style={{
                      color: "inherit",
                      textDecoration: "inherit",
                      fontFamily: "inherit",
                    }}
                    target="_blank"
                  >
                    Exporter CSV
                  </CSVLink>
                </VmVButton>
              </Box>
            </Box>
          </Box>
          <VmVDataArray
            cells={InscriptionsAndParticipationsDetailsCells}
            rows={filterData(formateData(data), filters)}
            showFooter
          />
        </Box>
      </Box>
    </Box>
  );
};

export const InscriptionsAndParticipationsDetailsChart = ({
  width,
  height,
  home,
}: {
  width?: number;
  height?: number;
  home?: boolean;
}): JSX.Element => {
  const { data } = useQuery({
    queryKey: ["etablissements/inscription-participations"],
    queryFn: async () =>
      await fetchData(
        "etablissements/inscription-participations?page=1&pageLimit=10000",
      ),
  });

  const size = useWindowSize();

  const getchartData = (dataToFormat: any): any[] => {
    if (dataToFormat === undefined || dataToFormat.length === 0) return [];
    const animation = [0, 0];
    const maintenance = [0, 0];
    const hotellerie = [0, 0];
    const accueil = [0, 0];
    const restauration = [0, 0];

    dataToFormat?.etablissements.forEach((element: any) => {
      animation[0] += parseInt(element?.nombre_disponibilites_animation ?? 0);
      animation[1] += parseInt(element?.nombre_participations_animation ?? 0);
      maintenance[0] += parseInt(
        element?.nombre_disponibilites_maintenance ?? 0,
      );
      maintenance[1] += parseInt(
        element?.nombre_participations_maintenance ?? 0,
      );
      hotellerie[0] +=
        parseInt(element?.nombre_disponibilites_hotellerie_ASH ?? 0) +
        parseInt(element?.nombre_disponibilites_hotellerie_serveur ?? 0);
      hotellerie[1] +=
        parseInt(element?.nombre_participations_hotellerie_serveur ?? 0) +
        parseInt(element?.nombre_participations_hotellerie_ASH ?? 0);
      accueil[0] += parseInt(
        element?.nombre_disponibilites_accueil_et_administration ?? 0,
      );
      accueil[1] += parseInt(
        element?.nombre_participations_accueil_et_administration ?? 0,
      );
      restauration[0] += parseInt(
        element?.nombre_disponibilites_restauration ?? 0,
      );
      restauration[1] += parseInt(
        element?.nombre_participations_restauration ?? 0,
      );
    });

    const totalAnimation = animation[0] + animation[1];
    const totalMaintenance = maintenance[0] + maintenance[1];
    const totalHotellerie = hotellerie[0] + hotellerie[1];
    const totalAccueil = accueil[0] + accueil[1];
    const totalRestauration = restauration[0] + restauration[1];
    const total =
      totalAnimation +
      totalMaintenance +
      totalHotellerie +
      totalAccueil +
      totalRestauration;

    return [
      {
        name: "Restauration",
        x: restauration[1],
        y: restauration[0],
        percentage: (totalRestauration / total) * 100,
      },
      {
        name: "Maintenance",
        x: maintenance[1],
        y: maintenance[0],
        percentage: (totalMaintenance / total) * 100,
      },
      {
        name: "Animation",
        x: animation[1],
        y: animation[0],
        percentage: (totalAnimation / total) * 100,
      },
      {
        name: "Accueil et administration",
        x: accueil[1],
        y: accueil[0],
        percentage: (totalAccueil / total) * 100,
      },
      {
        name: "Hôtellerie",
        x: hotellerie[1],
        y: hotellerie[0],
        percentage: (totalHotellerie / total) * 100,
      },
    ];
  };

  return (
    <Box
      alignSelf="center"
      display="flex"
      flexDirection="column"
      gap={2.5}
      mb={home === true ? 3 : 8}
    >
      <Box mt={8} alignSelf="center">
        <BarChart
          width={size.width > 1040 ? 900 : 800}
          height={height ?? 500}
          data={getchartData(data)}
        >
          <XAxis
            style={{
              color: "red",
            }}
            tick={{
              fill: colors.darkBlue,
            }}
            dataKey="name"
          />
          <YAxis tick={false} />
          <Bar dataKey="x" stackId="a" fill={colors.green}>
            <LabelList
              dataKey="percentage"
              position="top"
              formatter={(value) => `${value.toFixed(2)}%`}
            />
            {getchartData(data).map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors.green} />
            ))}
          </Bar>

          <Bar dataKey="y" stackId="a" fill={colors.orange50}>
            <LabelList
              dataKey="percentage"
              position="top"
              formatter={(value) => `${value.toFixed(2)}%`}
              fill={colors.green}
            />
            {getchartData(data).map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors.orange50} />
            ))}
          </Bar>
        </BarChart>
      </Box>
    </Box>
  );
};

export default InscriptionsAndParticipationsDetails;
