import { useEffect, useReducer, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import VmVButton from "../components/VmVButtons";
import ClinicsDropDowData from "../dropdownsData/clinique.json";
import RegionDropDowData from "../dropdownsData/regionClinique.json";
import { ReactComponent as LeftArrow } from "../assets/icons/left-arrow.svg";
import { colors, typography } from "../constants/theme";
import { ReactComponent as CSV } from "../assets/icons/csv.svg";
import VmVLabel from "../components/VmVLabel";
import VmVPie from "../components/VmVPie";
import VmVSelect from "../components/VmVSelect";
import VmVDataArray from "../components/VmVDataArray";
import { useNavigate } from "react-router-dom";
import VmVTextInput from "../components/VmVTextInput";
import { fetchData } from "../helpers/fetch";
import { useQuery } from "@tanstack/react-query";
import { convertDataToCSV, downloadCSV } from "../helpers/DataToCsv";
import formatDropDownData from "../helpers/formatDropDown";
import { CSVLink } from "react-csv";

const arrayCells = [
  "Nom de la région",
  "Nom de l’établissement",
  "Restauration",
  "Maintenance",
  "Animation",
  "Accueil et administration",
  "Hôtellerie",
];

interface IReducerState {
  text: string;
  clinique: string;
  region: string;
}

interface IReducerAction {
  type: string;
  payload: string;
}

const initialStat: IReducerState = {
  text: "",
  clinique: "",
  region: "",
};

const reducer = (
  state: IReducerState,
  action: IReducerAction,
): IReducerState => {
  switch (action.type) {
    case "SET_TEXT":
      return {
        ...state,
        text: action.payload,
      };
    case "SET_CLINIQUE":
      return {
        ...state,
        clinique: action.payload,
      };
    case "SET_REGION":
      return {
        ...state,
        region: action.payload,
      };
    case "RESET": {
      return {
        text: "",
        region: "",
        clinique: "",
      };
    }
    default:
      return state;
  }
};

const ParticipationsAndRegionsDetails2 = (): JSX.Element => {
  const navigate = useNavigate();
  const [filters, dispatch] = useReducer(reducer, initialStat);

  const { data } = useQuery({
    queryKey: ["regions/cliniques"],
    queryFn: async () =>
      await fetchData("regions/clinique?page=1&pageLimit=10000"),
  });

  const onClickDownloadCSV = (): void => {
    const csvData = convertDataToCSV(formatData(data));
    downloadCSV(csvData);
  };

  const formatData = (dataToFormat: any): any[] => {
    if (dataToFormat === undefined) return [];

    let formatedData: any[] = [];

    dataToFormat?.regionsStatsList.forEach((element: any) => {
      formatedData = [
        ...formatedData,
        {
          type: element?.etablissement?.type,
          "Nom de la région": element?.name,
          "Nom de l’établissement": element?.etablissement?.name,
          Restauration:
            element?.etablissement?.nombre_disponibilites_restauration,
          Maintenance:
            element?.etablissement?.nombre_disponibilites_maintenance,
          Animation: element?.etablissement?.nombre_disponibilites_animation,
          "Accueil et administration":
            element?.etablissement
              ?.nombre_disponibilites_accueil_et_administration,
          Hôtellerie:
            parseInt(
              element?.etablissement?.nombre_disponibilites_hotellerie_serveur,
            ) +
            parseInt(
              element?.etablissement?.nombre_disponibilites_hotellerie_ASH,
            ),
        },
      ];
    });

    return formatedData;
  };

  const filterData = (dataToFilter: any[], filters: IReducerState): any[] => {
    if (dataToFilter === undefined) return [];
    return dataToFilter
      .filter((value) => {
        return value["Nom de l’établissement"]
          .toLowerCase()
          .includes(filters.text.toLowerCase());
      })
      .filter((value) => {
        return (value.type as string)
          .replace("Clinique - ", "")
          .toLowerCase()
          .includes(filters.clinique.toLowerCase());
      })
      .filter((value) => {
        return (value["Nom de la région"] as string)
          .toLowerCase()
          .includes(filters.region.toLowerCase());
      });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <VmVButton
        onClick={() => {
          navigate("/");
        }}
        type="back"
        LeftIcon={<LeftArrow stroke={colors.darkGrey[200]} />}
      >
        Retour
      </VmVButton>
      <Box
        alignSelf="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography color={colors.darkBlue} sx={{ ...typography.title1 }}>
          Participation des établissements par région EHPAD
        </Typography>
        <ParticipationsAndRegionsDetails2Chart />
        <Box display="flex" flexDirection="column" gap={8}>
          <Box display="flex" justifyContent="center">
            <VmVTextInput
              value={filters.text}
              onChange={(event) => {
                dispatch({ type: "SET_TEXT", payload: event.target.value });
              }}
              placeholder="Rechercher un établissement"
            />
          </Box>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" gap={1}>
                <VmVButton
                  onClick={() => {
                    dispatch({ type: "RESET", payload: "" });
                  }}
                  type="primary"
                >
                  Réinitialiser
                </VmVButton>
                <VmVSelect
                  label="Tous les types de clinique"
                  options={formatDropDownData(ClinicsDropDowData)}
                  value={filters.clinique}
                  onChange={(value) => {
                    dispatch({ type: "SET_CLINIQUE", payload: value });
                  }}
                />
                <VmVSelect
                  label="Toutes les régions"
                  options={formatDropDownData(RegionDropDowData)}
                  value={filters.region}
                  onChange={(value) => {
                    dispatch({ type: "SET_REGION", payload: value });
                  }}
                />
              </Box>
              <Box>
                <VmVButton
                  onClick={() => {}}
                  type="secondary"
                  RightIcon={<CSV />}
                >
                  <CSVLink
                    data={formatData(data)}
                    filename={"data.csv"}
                    style={{
                      color: "inherit",
                      textDecoration: "inherit",
                      fontFamily: "inherit",
                    }}
                    target="_blank"
                  >
                    Exporter CSV
                  </CSVLink>
                </VmVButton>
              </Box>
            </Box>
          </Box>
          <VmVDataArray
            cells={arrayCells}
            rows={filterData(formatData(data), filters)}
            showFooter
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ParticipationsAndRegionsDetails2;

export const ParticipationsAndRegionsDetails2Chart = ({
  home,
}: {
  home?: boolean;
}): JSX.Element => {
  const { data } = useQuery({
    queryKey: ["regions/cliniques/graph"],
    queryFn: async () => await fetchData("regions/clinique/graph"),
  });

  const calculateTotal = (data: any): number => {
    return (
      parseInt(data?.totalDispoHotellerieASH ?? 0) +
      parseInt(data?.totalDispoHotellerieAccueilAdministration ?? 0) +
      parseInt(data?.totalDispoHotellerieAnimation ?? 0) +
      parseInt(data?.totalDispoHotellerieServer ?? 0) +
      parseInt(data?.totalDispoMaintenance ?? 0) +
      parseInt(data?.totalDispoRestauration ?? 0)
    );
  };

  return (
    <Box
      alignSelf="center"
      display="flex"
      flexDirection="column"
      gap={2.5}
      mb={8}
      width="100%"
    >
      <Box
        mt={8}
        alignSelf="center"
        width="100%"
        display="flex"
        flexDirection="column"
        gap={8}
      >
        <Grid container justifyContent="center" alignItems="center">
          {data?.regionsList.map((region: any, index) => {
            return (
              <Grid item xs={3} key={index}>
                <VmVPie
                  total={data?.totalNombreInscirptions}
                  color={home === true ? colors.blue200 : colors.yellow}
                  area={region?._id}
                  participantsNumber={region.count}
                />
              </Grid>
            );
          })}
        </Grid>
        {/* <Grid container gap={6} justifyContent="space-between">
          <Grid item xs={2.5}>
            <VmVPie
              color={home === true ? colors.blue200 : colors.yellow}
              area="ARA"
              participantsNumber={80}
            />
          </Grid>
          <Grid item xs={2.5}>
            <VmVPie
              color={home === true ? colors.blue200 : colors.yellow}
              area="ARA"
              participantsNumber={80}
            />
          </Grid>
          <Grid item xs={2.5}>
            <VmVPie
              color={home === true ? colors.blue200 : colors.yellow}
              area="ARA"
              participantsNumber={80}
            />
          </Grid>
          <Grid item xs={2.5}>
            <VmVPie
              color={home === true ? colors.blue200 : colors.yellow}
              area="ARA"
              participantsNumber={80}
            />
          </Grid>
        </Grid>
        <Grid container gap={6} justifyContent="space-around">
          <Grid item xs={2.5}>
            <VmVPie
              color={home === true ? colors.lightBlue200 : colors.blue50}
              area="ARA"
              participantsNumber={80}
            />
          </Grid>
          <Grid item xs={2.5}>
            <VmVPie
              color={home === true ? colors.lightBlue200 : colors.blue50}
              area="ARA"
              participantsNumber={80}
            />
          </Grid>
        </Grid> */}
      </Box>
      <VmVLabel
        color={home === true ? colors.blue200 : colors.yellow}
        label="Nombre de SSR inscrits"
      />
      <VmVLabel
        color={home === true ? colors.lightBlue200 : colors.blue50}
        label="Nombre de Santé Mentale inscrits"
      />
    </Box>
  );
};
