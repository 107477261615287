/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { colors, typography } from "../constants/theme";
import { ReactComponent as SortIcon } from "../assets/icons/sort.svg";
import {
  Box,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  type SelectChangeEvent,
  Typography,
  TableFooter,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// interface rowType {
//   [key: string]: string | undefined;
// }

interface IVmVDataArrayProps {
  cells: string[];
  rows: any[];
  width?: string;
  page?: number;
  pagination?: number;
  showFooter?: boolean;
  onChangePage?: (value: number) => void;
  onChangePagination?: (value: number) => void;
}

type Order = "asc" | "desc";

interface itemType {
  order: Order;
  orderBy: string;
}

const VmVDataArray = ({
  cells,
  rows,
  showFooter,
}: IVmVDataArrayProps): JSX.Element => {
  const [item, setItem] = React.useState<itemType>({
    order: "asc",
    orderBy: "",
  });
  const [page, setPage] = React.useState<number>(1);
  const [pagination, setPagination] = React.useState<number>(10);
  const { lightGrey } = colors;

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ): void => {
    setPage(value);
  };

  const handleChangePagination = (event: SelectChangeEvent): void => {
    setPagination(parseInt(event.target.value));
  };

  const getSortedData = (dataToSort: any[], sortItem: itemType): any[] => {
    if (sortItem.orderBy === "") return dataToSort;
    if (sortItem.order === "asc") {
      return [...dataToSort].sort((a, b) => {
        if (
          !Number.isNaN(Number(a[sortItem.orderBy])) &&
          !Number.isNaN(Number(b[sortItem.orderBy]))
        ) {
          return Number(a[sortItem.orderBy]) - Number(b[sortItem.orderBy]);
        }
        return String(a[sortItem.orderBy]).localeCompare(
          String(b[sortItem.orderBy]),
        );
      });
    }

    return [...dataToSort].sort((a, b) => {
      // if (
      //   moment(b[sortItem.orderBy]).isValid() &&
      //   moment(a[sortItem.orderBy]).isValid()
      // ) {
      //   return moment(b[sortItem.orderBy]) > moment(a[sortItem.orderBy]);
      // }
      if (
        !Number.isNaN(Number(a[sortItem.orderBy])) &&
        !Number.isNaN(Number(b[sortItem.orderBy]))
      ) {
        return Number(b[sortItem.orderBy]) - Number(a[sortItem.orderBy]);
      }
      return String(b[sortItem.orderBy]).localeCompare(
        String(a[sortItem.orderBy]),
      );
    });
  };

  const calculateSum = (data: any[], cell: string): number => {
    return data.reduce((acc, curr) => {
      if (curr[cell] !== undefined) {
        return acc + Number(curr[cell] ?? 0);
      }
      return acc;
    }, 0);
  };

  const handleClickSort = (cell: string, order: Order): void => {
    setItem((oldItem) => {
      return {
        order,
        orderBy: cell,
      };
    });
  };

  return (
    <Box width={"calc(100vw - 340px)"}>
      <TableContainer component={Box} width={"calc(100vw - 340px)"}>
        <Table
          style={{
            backgroundColor: colors.darkBlue,
          }}
        >
          <TableHead
            style={{
              backgroundColor: colors.darkBlue,
              color: colors.white,
            }}
          >
            <TableRow>
              {cells.map((cell) => (
                <TableCell
                  style={{
                    color: colors.white,
                    fontWeight: 400,
                    fontSize: "18px",
                    fontFamily: "Popins, sans-serif",
                  }}
                  key={cell}
                >
                  <Typography
                    display="flex"
                    alignItems="center"
                    minWidth={203}
                    gap={2}
                    justifyContent="space-between"
                  >
                    {cell}
                    {/* <SortIcon
                      onClick={() => {
                        handleClickSort(cell);
                      }}
                      cursor="pointer"
                    /> */}
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={0.1}
                    >
                      <KeyboardArrowUpIcon
                        onClick={() => {
                          handleClickSort(cell, "asc");
                        }}
                        sx={{ cursor: "pointer" }}
                      />

                      <KeyboardArrowDownIcon
                        onClick={() => {
                          handleClickSort(cell, "desc");
                        }}
                        sx={{ cursor: "pointer" }}
                      />
                    </Box>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              backgroundColor: colors.white,
            }}
          >
            {getSortedData(rows, item)
              .slice(pagination * page - pagination, pagination * page - 1)
              .map((row, rowIndex) => {
                return (
                  <TableRow key={rowIndex}>
                    {cells.map((cell, cellIndex) => {
                      return (
                        <TableCell
                          style={{
                            ...typography.body1,
                            color: colors.darkBlue,
                          }}
                          key={cellIndex}
                        >
                          {row[cell]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {showFooter !== undefined &&
              cells.map((cell, cellIndex) => {
                return (
                  <TableCell
                    key={cell}
                    style={{
                      ...typography.body1,
                      backgroundColor: colors.darkBlue,
                      color: colors.white,
                    }}
                  >
                    {cellIndex === 0
                      ? "Total"
                      : Number.isNaN(calculateSum(rows, cell))
                      ? "-"
                      : calculateSum(rows, cell)}
                  </TableCell>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={8}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Typography
            color={colors.darkBlue}
            sx={{
              ...typography.body1,
            }}
          >
            Afficher
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={`${pagination}`}
            onChange={handleChangePagination}
            IconComponent={ExpandMoreIcon}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0, // Remove the border
              },
            }}
            style={{
              minWidth: "82px",
              minHeight: "34px",
              height: "34px",
              backgroundColor: lightGrey[100],
              border: "unset",
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            {rows?.length > 10 ? <MenuItem value={20}>20</MenuItem> : null}
            {rows?.length > 20 ? <MenuItem value={50}>50</MenuItem> : null}
          </Select>

          <Typography
            color={colors.darkBlue}
            sx={{
              ...typography.body1,
            }}
          >
            par page
          </Typography>
        </Box>
        <Box>
          <Typography
            color={colors.darkBlue}
            sx={{
              ...typography.body1,
            }}
          >
            {page}/{Math.ceil(rows.length / pagination)}
            {/* {page}/
            {calculatePagination(
              rows.length,
              pagination !== undefined ? pagination : 10,
            )} */}
          </Typography>
        </Box>
        <Box>
          <Pagination
            // variant="outlined"
            onChange={handleChangePage}
            shape="rounded"
            count={Math.ceil(rows.length / pagination)}
            style={{
              color: colors.darkBlue,
            }}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: ArrowBackIosIcon,
                  next: ArrowForwardIosIcon,
                }}
                {...item}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default VmVDataArray;
