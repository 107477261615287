import { colors } from "../constants/theme";

export const Breakdown0fCollaboratorsArrayCells = [
  "Direction",
  "Fonction",
  "Mail",
  "Téléphone",
  "Droit à l’image",
];

export const Breakdown0fCollaboratorsArrayRows = [
  {
    Direction: "Direction des modèles opérationnells anniamtions des équipes",
    Fonction: "Chef de projet",
    Téléphone: "prenom.nom@korian.fr",
    "Droit à l’image": "Oui",
    Mail: "+33 6 06 06 06 06",
  },
  {
    Direction: "Direction des modèles opérationnells anniamtions des équipes",
    Fonction: "Chef de projet",
    Téléphone: "prenom.nom@korian.fr",
    "Droit à l’image": "Oui",
    Mail: "+33 6 06 06 06 06",
  },
  {
    Direction: "Direction des modèles opérationnells anniamtions des équipes",
    Fonction: "Chef de projet",
    Téléphone: "prenom.nom@korian.fr",
    "Droit à l’image": "Oui",
    Mail: "+33 6 06 06 06 06",
  },
  {
    Direction: "Direction des modèles opérationnells anniamtions des équipes",
    Fonction: "Chef de projet",
    Téléphone: "prenom.nom@korian.fr",
    "Droit à l’image": "Oui",
    Mail: "+33 6 06 06 06 06",
  },
  {
    Direction: "Direction des modèles opérationnells anniamtions des équipes",
    Fonction: "Chef de projet",
    Téléphone: "prenom.nom@korian.fr",
    "Droit à l’image": "Oui",
    Mail: "+33 6 06 06 06 06",
  },
];

export const Breakdown0fCollaboratorsData = [
  {
    name: "Group A",
    value: 80,
    fill: colors.green,
  },
  {
    name: "Group A",
    value: 20,
    fill: "transparent",
  },
];
