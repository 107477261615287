import { Box, Typography } from "@mui/material";
import React from "react";
import { colors, typography } from "../constants/theme";

interface IVMVPaper {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  height?: number;
}

const VMVPaper = ({ children, title, height }: IVMVPaper): JSX.Element => {
  const { lightGrey, darkBlue } = colors;
  const { title1 } = typography;
  return (
    <Box
      sx={{
        width: "100%",
        padding: "48px 0",
        backgroundColor: lightGrey[100],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: "center",
        // gap: "24px",
        borderRadius: "20px",
        height: height ?? "auto",
        minWidth: "475px",
      }}
    >
      <Typography color={darkBlue} textAlign="center" sx={{ ...title1 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default VMVPaper;
