import axios from "axios";

export const fetchData = async (subURL: string): Promise<any> => {
  const URL =
    process.env.React_APP_VMV_URL ??
    "https://api.dev.korian-solutions.fr/vmv/stats/";

  const { data } = await axios.get(`${URL}${subURL}`);
  return data;
};

export const fetchDropDownData = async (subURL: string): Promise<any> => {
  const URL =
    process.env.React_APP_VMV_URL_DROP_DOWN ??
    "https://ase-vmv-back-dev.azurewebsites.net/";

  const { data } = await axios.get(`${URL}${subURL}`);
  return data;
};
