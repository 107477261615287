import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const UnAuthenticatedLayout = (): JSX.Element => {
  return (
    <>
      <Outlet />
      <Box position="fixed" bottom={0} left={0} zIndex={-1}>
        <Footer />
      </Box>
    </>
  );
};

export default UnAuthenticatedLayout;
