import { Box, Typography } from "@mui/material";
import React from "react";
import { colors, typography } from "../constants/theme";

interface IVmVCardKeysProps {
  title: string;
  subtitle: string;
  description?: string;

  color?: "green" | "orange";
}

const VmVCardKeys = ({
  title,
  subtitle,
  description,
  color = "orange",
}: IVmVCardKeysProps): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p="48px"
      gap="24px"
    >
      <Typography
        color={colors.darkBlue}
        textAlign="center"
        fontFamily="Montserrat"
        fontWeight="bold"
        fontSize="24px"
      >
        {subtitle}
      </Typography>
      <Box>
        <Typography
          color={colors[color]}
          textAlign="center"
          fontFamily="Montserrat"
          fontWeight="normal"
          fontSize="24px"
          mb={description !== undefined ? 1 : 0}
        >
          {title}
        </Typography>
        <Typography
          color={colors[color]}
          textAlign="center"
          sx={{ ...typography.body1 }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default VmVCardKeys;
