/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Typography } from "@mui/material";
import React from "react";
import { colors, typography } from "../constants/theme";
import { useMsal } from "@azure/msal-react";

const SignIn = (): JSX.Element => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    await instance.loginRedirect();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: "#809EAE",
      }}
      width="100%"
      height="100vh"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={4}
        sx={{
          backgroundColor: colors.white,
          boxShadow: colors.dropShadow[300],
          padding: "32px",
          borderRadius: "20px",
        }}
      >
        <Typography
          color={colors.blue}
          textAlign="center"
          sx={{ ...typography.title1 }}
        >
          Connexion
        </Typography>
        <Typography
          color={colors.darkBlue}
          textAlign="left"
          sx={{ ...typography.body1 }}
        >
          Connectez-vous à l’aide de vos identifiants professionnels.
        </Typography>
        <Box
          onClick={handleLogin}
          px={4}
          py={2}
          borderRadius={10}
          boxShadow={colors.dropShadow[300]}
          alignSelf="center"
          width="fit-content"
          sx={{
            background:
              "linear-gradient(246.3deg, #003E5C 14.46%, #00AEC3 84.5%)",
            cursor: "pointer",
          }}
        >
          <Typography color={colors.white}>Se connecter</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
