/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Grid, Typography } from "@mui/material";
import VmVButton from "../components/VmVButtons";
import { ReactComponent as LeftArrow } from "../assets/icons/left-arrow.svg";
import { colors, typography } from "../constants/theme";
import directionDropDownData from "../dropdownsData/direction.json";
// import VmVSelect from "../components/VmVSelect";
import { ReactComponent as CSV } from "../assets/icons/csv.svg";
import VmVDataArray from "../components/VmVDataArray";
import { PieChart, Pie, Label } from "recharts";
import VmVSelect from "../components/VmVSelect";
import { useLocation, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Breakdown0fCollaboratorsArrayCells } from "../data/Breakdown0fCollaborators";
import { useQuery } from "@tanstack/react-query";
import { fetchData } from "../helpers/fetch";
import formatDropDownData from "../helpers/formatDropDown";
import { useState } from "react";

const Breakdown0fCollaborators = (): JSX.Element => {
  const [filter, setFilter] = useState<string>("");

  const navigate = useNavigate();
  const { data } = useQuery({
    queryKey: ["collaborateurs"],
    queryFn: async () =>
      await fetchData("collaborateurs?page=1&pageLimit=10000"),
  });

  const formatData = (dataToFormat: any): any[] => {
    const formattedData: any[] = [];

    if (dataToFormat !== undefined) {
      (dataToFormat?.collaborateurs as any[])?.forEach(
        ({ hasDroitImage, email, direction, fonction, phone }) => {
          formattedData.push({
            Direction: direction,
            Fonction: fonction,
            Téléphone: phone,
            "Droit à l’image": hasDroitImage === true ? "Oui" : "Non",
            Mail: email,
          });
        },
      );
    }

    return formattedData;
  };

  const filterData = (dataToFilter: any, filterStr: string): any[] => {
    if (filterStr === "") return dataToFilter;

    return dataToFilter.filter((element) =>
      (element.Direction as string)
        ?.toLowerCase()
        ?.replace(" ", "")
        .includes(filterStr.toLowerCase().replace(" ", "")),
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <VmVButton
        onClick={() => {
          navigate("/");
        }}
        type="back"
        LeftIcon={<LeftArrow stroke={colors.darkGrey[200]} fontSize="16px" />}
      >
        Retour
      </VmVButton>
      <Box
        alignSelf="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography color={colors.darkBlue} sx={{ ...typography.title1 }}>
          Répartitions des collaborateurs(trices)
        </Typography>

        <Breakdown0fCollaboratorsChart />

        <Box display="flex" flexDirection="column" gap={8}>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" gap={1}>
                <VmVButton
                  onClick={() => {
                    setFilter("");
                  }}
                  type="primary"
                >
                  Réinitialiser
                </VmVButton>
                <VmVSelect
                  value={filter}
                  onChange={(value) => {
                    setFilter(value);
                  }}
                  label="Toutes les directions"
                  options={formatDropDownData(directionDropDownData)}
                />
              </Box>
              <Box>
                <VmVButton
                  onClick={() => {}}
                  type="secondary"
                  RightIcon={<CSV />}
                >
                  <CSVLink
                    data={formatData(data)}
                    filename={"Répartitions des collaborateurs(trices).csv"}
                    style={{
                      color: "inherit",
                      textDecoration: "inherit",
                      fontFamily: "inherit",
                    }}
                    target="_blank"
                  >
                    Exporter CSV
                  </CSVLink>
                </VmVButton>
              </Box>
            </Box>
          </Box>
          <VmVDataArray
            cells={Breakdown0fCollaboratorsArrayCells}
            rows={filterData(formatData(data), filter)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Breakdown0fCollaborators;

export const Breakdown0fCollaboratorsChart = ({
  direction,
}: {
  direction?: string;
}): JSX.Element => {
  const { pathname } = useLocation();

  const { data } = useQuery({
    queryKey: ["collaborateurs/repartition-par-direction"],
    queryFn: async () =>
      await fetchData("collaborateurs/repartition-par-direction"),
  });

  const formatData = (
    dataToFormat: any,
  ): Array<{
    name: string;
    value: any;
    fill: string;
  }> => {
    if (dataToFormat !== undefined) {
      return [
        {
          name: "Group A",
          value: dataToFormat,
          fill: colors.green,
        },
        {
          name: "Group A",
          value: 100 - parseFloat(dataToFormat),
          fill: colors.lightGrey[pathname === "/" ? 200 : 100],

          // fill: "white",
        },
      ];
    }

    return [];
  };

  const calculatePercentage = (docs?: number, totale?: number): string => {
    return ((docs! / totale!) * 100).toFixed(2) + "%";
  };

  return (
    <Box
      // alignSelf="center"
      // display="flex"
      // flexDirection="column"
      // gap={2}
      // mt={direction === "column" ? 3 : 0}
      mb={direction === "column" ? 4 : 8}
      mx={direction === "column" ? 6 : 0}
    >
      <Grid container justifyContent="center">
        {data?.allDirection
          ?.slice(0, direction === "column" ? 5 : data?.allDirection?.length)
          ?.map((direction: any) => {
            return (
              <Grid key={direction?._id} item xs={pathname === "/" ? 12 : 4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  <PieChart width={250} height={300}>
                    <Pie
                      data={formatData(
                        (direction?.totaldocs! / data?.total) * 100,
                      )}
                      dataKey="value"
                      nameKey="name"
                      cx={120}
                      cy={200}
                      innerRadius={60}
                      outerRadius={80}
                      fill={colors.green}
                    >
                      <Label
                        value={calculatePercentage(
                          direction?.totaldocs,
                          data?.total,
                        )}
                        position="center"
                        color={colors.darkBlue}
                        fill={colors.darkBlue}
                        style={{
                          ...typography.body1,
                          fontWeight: 600,
                        }}
                      />
                    </Pie>
                  </PieChart>
                  <Typography
                    textAlign="center"
                    color={colors.darkBlue}
                    sx={{
                      ...typography.body1,
                    }}
                  >
                    {direction?._id}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
