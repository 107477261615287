import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { colors, typography } from "../constants/theme";
import Select, { components } from "react-select";

const CaretDownIcon = (): JSX.Element => {
  return (
    <ExpandMoreIcon
      style={{ fill: colors.darkBlue, width: "20px", height: "20px" }}
    />
  );
};

const DropdownIndicator = (props): JSX.Element => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

const Placeholder = (props): JSX.Element => {
  return <components.Placeholder {...props} />;
};

interface IVmVSelectProps {
  label: string;
  options?: IOption[];
  value?: string;
  onChange?: (value: string) => void;
  fullWidth?: boolean;
}

interface IOption {
  value: string;
  label: string;
}

const VmVSelect = ({
  options,
  label,
  value,
  fullWidth,
  onChange,
}: IVmVSelectProps): JSX.Element => {
  return (
    <Select
      value={options?.find((option) => option.value === value) ?? null}
      onChange={(option) => {
        if (onChange !== undefined) onChange(option?.value ?? "");
      }}
      isClearable
      options={options}
      placeholder={label}
      getOptionLabel={(option: IOption) => option.label}
      getOptionValue={(option: IOption) => option.value}
      styles={customStyles}
      components={{ Placeholder, DropdownIndicator, IndicatorSeparator: null }}
    />
  );
};

export default VmVSelect;

const customStyles = {
  indicatorsContainer: (base) => ({
    ...base,
    color: colors.darkBlue,
  }),
  placeholder: (base) => ({
    ...base,
    color: colors.darkBlue,
    ...typography.body1,
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    height: 52,
    minHeight: 42,
    minWidth: 315,
    borderColor: colors.darkBlue + " !important",
    borderRadius: "6px",
    backgroundColor: colors.lightGrey[100],
  }),
  menu(base, props) {
    return {
      ...base,
      border: "unset",
      boxShadow: colors.dropShadow[200],
      borderRadius: "6px",
      backgroundColor: colors.white,
      color: colors.darkBlue,
      padding: "16px",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      ...typography.body1,
    };
  },
};
