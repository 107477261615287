import React from "react";
import { colors } from "../constants/theme";

interface IVmVTextInputProps {
  placeholder: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const inputStyle = {
  "&::placeholder": {
    color: "#AFAFAF",
  },
  "& :focus": {
    outline: "none",
    outlineOffset: "none",
  },
  "& :focusVisible": {
    outline: "unset",
    outlineOffset: "unset",
  },
};

const VmVTextInput = ({
  placeholder,
  value,
  onChange,
}: IVmVTextInputProps): JSX.Element => {
  return (
    <input
      onChange={onChange}
      type="text"
      value={value}
      placeholder={placeholder}
      style={{
        width: "301px",
        height: "42px",
        borderRadius: 6,
        border: "unset",
        boxShadow: "inset -1px 1px 5px rgba(0, 0, 0, 0.2)",
        padding: "0 16px",
        gap: "12px",
        display: "flex",
        alignItems: "center",

        paddingLeft: 30,
        color: colors.darkBlue,
        ...inputStyle,
      }}
    />
  );
};

export default VmVTextInput;
