export const colors = {
  darkBlue: "#003E5C",
  blue: "#00AEC3",
  white: "#FFFFFF",
  orange: "#EF7D00",
  green: "#91BE1E",
  yellow: "#FFD205",
  blue50: "#80D4E1",
  blue200: "#008093",
  lightBlue200: "#CCD8DE",
  orange50: "#F7BE80",
  lightGrey: {
    100: "#F6F6F6",
    200: "#DFDFDF",
  },
  darkGrey: {
    200: "#58585A",
  },
  dropShadow: {
    100: "0px 0px 4px rgba(0, 0, 0, 0.2)",
    200: "0px 1px 14px rgba(0, 0, 0, 0.1)",
    300: "0px 1px 14px rgba(0, 0, 0, 0.2)",
  },
};

export const typography = {
  mainTitle: {
    fontFamily: "Century Gothic, Montserrat",
    fontSize: "48px",
    lineHeight: "60px",
    fontWeight: 700,
  },
  title1: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "29px",
    fontWeight: 700,
    // textTransform: "capitalize",
  },
  title2: {
    fontFamily: "Century Gothic, Montserrat",
    fontSize: "48px",
    lineHeight: "59px",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  body1: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
  },
};
