/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";
import { AppBar, Box, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../assets/icons/logo.svg";

import { colors, typography } from "../constants/theme";
import { useMsal } from "@azure/msal-react";

const Header = (): JSX.Element => {
  const { instance } = useMsal();
  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: colors.white,
        boxShadow: colors.dropShadow[200],
        height: "102px",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mx={5}
        mt={1}
      >
        <Logo />
        <Box display="flex" alignItems="center" gap={6}>
          {/* <Box display="flex" alignItems="center" gap={1}>
            <Box mt={1}>
              <Account width="42px" height="42px" />
            </Box>

            <Typography color={colors.darkBlue} sx={{ ...typography.body1 }}>
              {user?.fullname}
            </Typography>
          </Box> */}
          <Typography
            onClick={async () => {
              instance.logoutRedirect();
            }}
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              ...typography.body1,
            }}
            color={colors.blue}
            textAlign="right"
          >
            Déconnexion
          </Typography>
        </Box>
      </Box>
    </AppBar>
  );
};

export default Header;
