import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export interface IUser {
  name?: string;
  email?: string;
  b2cToken?: string;
  [key: string]: any;
}

const userAtom = atom<IUser | null>({
  key: "user_atom",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export default userAtom;
