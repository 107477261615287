import { Box, Typography } from "@mui/material";
import React from "react";
import { colors, typography } from "../constants/theme";
import VmVButton from "../components/VmVButtons";
import { ReactComponent as LeftArrow } from "../assets/icons/left-arrow.svg";
import { Link } from "react-router-dom";

const PageNotFound = (): JSX.Element => {
  const { white, dropShadow, darkBlue, blue } = colors;
  const { title1, body1 } = typography;
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100vh"
      >
        <Box
          width="fit-content"
          display="flex"
          flexDirection="column"
          gap={4}
          bgcolor={white}
          boxShadow={dropShadow[300]}
          padding={4}
          borderRadius={5}
        >
          <Typography color={blue} textAlign="center" sx={{ ...title1 }}>
            Page non trouvée
          </Typography>
          <Box>
            <Typography color={darkBlue} textAlign="left" sx={{ ...body1 }}>
              Nous n’arrivons pas à trouver la page que vous recherchez.
            </Typography>
            <Typography color={darkBlue} textAlign="left" sx={{ ...body1 }}>
              Il se peut que l’URL saisie soit mal orthographié ou que la page
              que vous recherchez n’existe plus.
            </Typography>
          </Box>
          <Box alignSelf="center">
            <Link to="/" style={{ textDecoration: "unset" }}>
              <VmVButton
                onClick={() => {}}
                type="secondary"
                LeftIcon={<LeftArrow />}
              >
                Revenir à la page d’accueil
              </VmVButton>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PageNotFound;
