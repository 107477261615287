/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { LogLevel } from "@azure/msal-browser";

const userFlow = process.env.REACT_APP_USER_FLOW_VMV
  ? process.env.REACT_APP_USER_FLOW_VMV
  : "";
const authority = process.env.REACT_APP_AUTHORITY_VMV
  ? process.env.REACT_APP_AUTHORITY_VMV
  : "";
const authorityDomain = process.env.REACT_APP_AUTHORITY_DOMAIN_VMV
  ? process.env.REACT_APP_AUTHORITY_DOMAIN_VMV
  : "";
const clientId = process.env.REACT_APP_CLIENT_ID_VMV
  ? process.env.REACT_APP_CLIENT_ID_VMV
  : "";
const redirectUri = process.env.REACT_APP_API_REDIRECT_URI
  ? process.env.REACT_APP_API_REDIRECT_URI
  : "";

export const b2cPolicies = {
  names: {
    signUpSignIn: userFlow,
    forgotPassword: userFlow,
    editProfile: userFlow,
  },
  authorities: {
    signUpSignIn: {
      authority: `${authority}/${userFlow}`,
    },
    editProfile: {
      authority: `${authority}/${userFlow}`,
    },
    forgotPassword: {
      authority: `${authority}/${userFlow}`,
      scopes: [],
    },
  },
  authorityDomain,
};

export const msalConfig = {
  auth: {
    clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri,
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["user.read", "mail.send"],
};
