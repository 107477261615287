/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Typography } from "@mui/material";
import VmVButton from "../components/VmVButtons";
import { ReactComponent as LeftArrow } from "../assets/icons/left-arrow.svg";
import { colors, typography } from "../constants/theme";
import VmVLabel from "../components/VmVLabel";
import { ReactComponent as CSV } from "../assets/icons/csv.svg";
import VmVDataArray from "../components/VmVDataArray";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../helpers/fetch";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { convertDataToCSV, downloadCSV } from "../helpers/DataToCsv";
import { InscriptionsParticipationsDetailsCells } from "../data/InscriptionsParticipationsDetailsData";
import VmVCalender from "../components/VmVCalender";
import { type ReactNode, useState } from "react";
import { CSVLink } from "react-csv";

const InscriptionsParticipationsDetails = (): JSX.Element => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const { data } = useQuery({
    queryKey: ["DatesInscriptions"],
    queryFn: async () =>
      await fetchData(`dates-inscriptions?page=1&pageLimit=10000`),
  });

  const formateData = (): any[] => {
    let reformateData: any[] = [];

    data?.datesInscriptionsList.forEach((element) => {
      reformateData = [
        ...reformateData,
        {
          date: element?.date,
          Date: moment(element?.date).format("DD/MM/YYYY"),
          "Nombres d’inscriptions collaborateur(trice)s":
            element?.nombre_collaborateurs,
          "Nombre de créneaux proposés par les établissements":
            element?.nombre_etablissements,
        },
      ];
    });

    return reformateData;
  };

  const filterData = (dataToFilter: any[], filter: any): any[] => {
    if (filter === null) return dataToFilter;

    return dataToFilter.filter((element) => {
      const date1 = moment(element.date).format("DD/MM/YYYY");
      const date2 = moment(filter).format("DD/MM/YYYY");
      return date1 === date2;
    });
  };

  const onClickDownloadCSV = (): void => {
    const csvData = convertDataToCSV(formateData());
    downloadCSV(csvData);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <VmVButton
        onClick={() => {
          navigate(-1);
        }}
        type="back"
        LeftIcon={<LeftArrow stroke={colors.darkGrey[200]} />}
      >
        Retour
      </VmVButton>
      <Box
        alignSelf="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography color={colors.darkBlue} sx={{ ...typography.title1 }}>
          Détails des inscriptions et des participations
        </Typography>
        <InscriptionsParticipationsDetailsChart />
        <Box display="flex" flexDirection="column" gap={8}>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" gap={1}>
                <VmVButton
                  onClick={() => {
                    setStartDate(null);
                  }}
                  type="primary"
                >
                  Réinitialiser
                </VmVButton>
                <VmVCalender
                  date={startDate}
                  handleChangeDate={(date) => {
                    setStartDate(date);
                  }}
                />
                {/* <VmVSelect label="Toutes les dates" options={[]} /> */}
              </Box>
              <Box>
                <VmVButton
                  onClick={() => {}}
                  type="secondary"
                  RightIcon={<CSV />}
                >
                  <CSVLink
                    data={formateData()}
                    filename={"data.csv"}
                    style={{
                      color: "inherit",
                      textDecoration: "inherit",
                      fontFamily: "inherit",
                    }}
                    target="_blank"
                  >
                    Exporter CSV
                  </CSVLink>
                </VmVButton>
              </Box>
            </Box>
          </Box>
          <VmVDataArray
            cells={InscriptionsParticipationsDetailsCells}
            rows={filterData(formateData(), startDate)}
            showFooter
          />
        </Box>
      </Box>
    </Box>
  );
};

export const InscriptionsParticipationsDetailsChart = ({
  width,
  height,
  home,
}: {
  width?: number;
  height?: number;
  home?: boolean;
}): JSX.Element => {
  const { data } = useQuery({
    queryKey: ["DatesInscriptions"],
    queryFn: async () =>
      await fetchData(`dates-inscriptions?page=1&pageLimit=10000&sortDate=1`),
  });

  const formateData = (): any[] => {
    // let reformateData: any[] = [];
    let collab: any[] = [];
    let etab: any[] = [];

    data?.datesInscriptionsList.forEach((element, index) => {
      collab = [
        ...collab,
        {
          Date: moment(element?.date).format("DD/MM/YYYY"),
          "Nombres d’inscriptions collaborateur(trice)s":
            element?.nombre_collaborateurs,

          "Nombre de créneaux proposés par les établissements": null,
        },
      ];
      etab = [
        ...etab,
        {
          Date: moment(element?.date).format("DD/MM/YYYY"),
          "Nombres d’inscriptions collaborateur(trice)s": null,
          "Nombre de créneaux proposés par les établissements":
            element?.nombre_etablissements,
        },
      ];
    });

    return [
      ...etab,
      {
        Date: null,
        "Nombres d’inscriptions collaborateur(trice)s": 0,
        "Nombre de créneaux proposés par les établissements": 0,
      },
      ...collab,
    ];
  };

  return (
    <Box
      alignSelf="center"
      display="flex"
      flexDirection="column"
      // gap={2.5}
      mb={home ? 3 : 8}
    >
      <Box mt={home ? 3 : 8} alignSelf="center">
        <LineChart
          width={width ?? 990}
          height={height ?? 500}
          data={formateData()}
        >
          <CartesianGrid strokeDasharray="0 0" />
          <XAxis tick={false} color={colors.darkBlue} width={2} />
          <YAxis tick={false} color={colors.darkBlue} width={2} />
          <Line
            type="monotone"
            dataKey="Nombre de créneaux proposés par les établissements"
            stroke={colors.orange}
          />
          <Line
            type="monotone"
            dataKey="Nombres d’inscriptions collaborateur(trice)s"
            stroke={colors.green}
          />
          {home === undefined && <Tooltip content={<CustomTooltip />} />}
        </LineChart>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <VmVLabel color={colors.green} label="Collaborateurs(trices)" />
        <VmVLabel color={colors.orange} label="Etablissements" />
      </Box>
    </Box>
  );
};

export default InscriptionsParticipationsDetails;

const CustomTooltip = ({ active, payload, label }: any): JSX.Element | null => {
  if (active && payload && payload.length) {
    if (payload[0]?.payload.Date == null) return null;
    return (
      <div className="custom-tooltip">
        <p>Date: {payload[0]?.payload.Date}</p>
        {payload[0]?.payload[
          "Nombre de créneaux proposés par les établissements"
        ] !== null && (
          <p className="etab">
            Etablissements:{" "}
            {
              payload[0]?.payload[
                "Nombre de créneaux proposés par les établissements"
              ]
            }
          </p>
        )}

        {payload[0]?.payload["Nombres d’inscriptions collaborateur(trice)s"] !==
          null && (
          <p className="collab">
            Collaborateurs(trices):{" "}
            {
              payload[0]?.payload[
                "Nombres d’inscriptions collaborateur(trice)s"
              ]
            }
          </p>
        )}
      </div>
    );
  }

  return null;
};
